import { useRouter } from "next/navigation";
import { baseUrl } from "./constants";

export const useLogout = () => {
  const router = useRouter();

  const logout = async () => {
    try {
      const url = new URL("/auth/logout", baseUrl);
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.status === 200) {
        document.cookie = "SRSESSION=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        location.href = "/login";
      } else {
        console.error("Logout failed.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return {
    logout,
  };
};
